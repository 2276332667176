import React from "react";
// Customizable Area Start
import {  Box, Typography, Grid,  TextField, FormControlLabel, Radio, RadioGroup, Avatar, IconButton, createTheme } from "@material-ui/core";
import SideNavbar from "../../../components/src/SideNavbar.web";
import Sidebar from "../../../components/src/Sidebar.web";
import { info, des, down, milestone } from "../../appointmentmanagement/src/assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import PushnotificationsController, {
  Props,
} from "./PushnotificationsController";
import CustomDropDownWithSearch from "../../../components/src/CustomDropDownWithSearch";
import { buttonCancel } from "../../email-account-registration/src/assets";

export default class Pushnotifications extends PushnotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderTitleDesView = () => {
    return (
      <>
        <Box style={{
          display: 'flex', paddingLeft: '12px',
          paddingRight: '8px',
          paddingTop: '4px',
          paddingBottom: '4px',
          border: '1px solid #ccc',
          cursor: 'pointer',
          alignItems: 'center',
          borderRadius: '5px',
          marginTop: '20px'
        }}>
          <img src={info} style={{ width: "20px", height: "20px", marginRight: "5px" }} />
          <TextField
            placeholder="Notification Title"
            InputProps={{ disableUnderline: true }} // Disable underline
            style={{
              color: 'var(--Grey-Base, #939393)',
              fontFamily: 'Inter',
              fontSize: '6px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              width: '100%'
            }}
            value={this.state.title}
            data-test-id="titleNotification"
            onChange={this.changeTitle}
          />

        </Box>
        <Typography style={webStyle.passworderror}>{this.state.errorTitle}</Typography>
      </>
    )
  }
  renderNotification() {
    return (
      <Box style={{ marginTop: '20px' }}>
        <div style={{ position: 'relative', width: '100%' }}>

          <div
            data-test-id='getnotification'
            style={{
              display: 'flex',
              padding: '10px',
              border: '1px solid #ccc',
              cursor: 'pointer',
              alignItems: 'center',
              borderRadius: '5px',
              justifyContent: 'space-between'
            }} onClick={() => this.setState({openNotify:!this.state.openNotify})}>
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }} >
              <img src={milestone} style={{ height: "20px", marginRight: "5px" }} />


              <span style={this.state.notificationSelect ? {
                color: '#454545',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              } : {
                color: '#939393',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }}>
                {this.state.notificationSelect || 'Notification Type'}
              </span>
            </div>
            <img src={down} />
          </div>
          {this.renderMilestonArray(this.state.openNotify)}

        </div>
        <Typography style={webStyle.passworderror}>{this.state.errorType}</Typography>
      </Box>
    )
  }
  renderParticipentsList = (item: any, flag: boolean) => {
    return (
      <Grid container spacing={1} style={{  backgroundColor: '#f2f3f5',borderRadius:'10px',alignItems:'center'}}>
        <Grid item xs={2}>
          <Avatar style={webStyle.avatarstyle}>
            {this.getInitials(item.full_name)}
          </Avatar>
        </Grid>
        <Grid item xs={6}>
          <Grid container >
            <Grid item xs={12}>
              <Typography style={webStyle.fullname}>{item.full_name}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} >
          {flag && (
            
            <IconButton data-test-id="cancel-tech1" onClick={() => this.deleteItem(item)}>
              <img src={buttonCancel} alt="buttonCancel" />
            </IconButton>
          )}

        </Grid>
      </Grid>
    )
  }
  renderMilestonArray = (flag: any) => {
    return (
      <>{flag && (
        <div style={{
          position: 'absolute',
          top: '100%',
          left: '0',
          width: '100%',
          backgroundColor: '#fff', /* Background color for options */
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '5px',
          zIndex: 100
        }}>{this.state.notificationType && this.state.notificationType.length > 0 && (
          <>
            {this.state.notificationType?.map((option: any, index: any) => (
              <div key={index} style={{
                padding: '8px',
                cursor: 'pointer'
              }}
                data-test-id="selecttype"
                onClick={() => this.handleOptionClick(option)}>
                <span style={{
                  color: '#585858', fontFamily: 'Jost',
                  fontSize: '14px', fontStyle: 'normal', fontWeight: 400,
                  lineHeight: 'normal'
                }}>{option}</span>

              </div>
            ))}
          </>
        )}
        </div>
      )}</>
    )
  }
  renderDescription(){
    return(
      <>
     <Box style={{
    display: 'flex',
    paddingLeft: '12px',
    paddingRight: '8px',
    paddingTop: '4px',
    paddingBottom: '4px',
    height: '120px',
    border: '1px solid #ccc',
    cursor: 'pointer',
    alignItems: 'left',
    borderRadius: '5px',
    marginTop: '20px',
    overflow: 'auto' // Add overflow for scrolling
}}>
    <img src={des} style={{ width: "20px", height: "20px", marginRight: "5px", padding: '2px', marginTop: '5px' }} />

    <TextField
        placeholder="Notification Message"
        InputProps={{ disableUnderline: true, }} // Disable underline
        style={{
            color: 'var(--Grey-Base, #939393)',
            fontFamily: 'Inter',
            fontSize: '6px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            width: '100%' 
        }}
        multiline
        value={this.state.notificationDecription}
        data-test-id="notidescription"
        onChange={this.handleDescriptionChange}
    />
</Box>
        <Typography style={webStyle.passworderror}>{this.state.errorContents}</Typography>
       </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <>
        <SideNavbar
          navigation={this.props.navigation}
          id={this.props.id}
          showStudy={true}
          showButtonChats
          showButtonNotification
        />

        <Box style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
          <Sidebar navigation={this.props.navigation} id={this.props.id} selected="send_notification" />
        <Grid container>
          <Grid item xs={12} sm={9}>
          <Box
            style={webStyle.mainBox}>
            <Box style={webStyle.boxNew}>
              <Typography style={webStyle.box2}>Notifications</Typography>

            </Box>
            <Box style={{
              marginBottom: "10px"
            }}>
              <Typography style={webStyle.para}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Typography>
            </Box>

            <Box style={{ marginTop: '20px' }}>
              <Grid container spacing={2}>
                {/* First row with two columns */}
                <Grid item xs={12} sm={6}>
                  {this.renderTitleDesView()}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {this.renderNotification()}
                </Grid>

                {/* Second row with one column */}
                <Grid item xs={12} style={{height:"200px"}}>
                 {this.renderDescription()}
                </Grid>
              </Grid>
            </Box>

            <Box >
            <Typography  style={{
              color: 'var(--Grey-Base, #939393)',
              fontFamily: 'Inter',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal'
            }}>Notification Send To</Typography>
            </Box>
            <Box style={{ marginTop: '20px', }}>
          <RadioGroup
            name="status"
            data-test-id="radio1"
             value={this.state.selectedRadioMonth}
             onChange={this.handleOptionChangeRadioMonth}
           row
          >
          
                <FormControlLabel
                  value="all"
                  control={<Radio color="primary" />}
                  label={
                    <Typography
                      style={{
                        color: '#5A5A5A',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '26px',
                      }}>
                      All
                    </Typography>}
                />
            
                <FormControlLabel
                  value="selected"
                  control={<Radio color="primary" />}
                  label={<Typography
                    style={{
                      color: '#5A5A5A',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '26px',
                    }}>
                    Selected User
                  </Typography>}
                />

             

          </RadioGroup>


        </Box>

        <Box 
       
        style={{ marginTop: '20px'  }} >
            <CustomDropDownWithSearch
              edit={this.getData}
              options={this.state.patientList}
              onSelect={this.handleSelect}
              SelectedPatients={this.state.addPatientList}
              disableflag={this.state.disableFlag}
            />
          </Box> 
          {this.state.addPatientList &&  !this.state.disableFlag && (

<Box style={{ marginTop: '20px' }}>
<Grid container spacing={3}>
        {this.state.addPatientList.map((item:any, index:any) => (
          <Grid item xs={4} key={index}>
         {this.renderParticipentsList(item, true)}
          </Grid>
        ))}
      </Grid>
 
</Box>

)}
          <Box style={{
              borderRadius: '8px',
              border: '2px solid #A046FB', padding: '5px',
              marginTop:'20px',width:"70px", alignItems: 'center',
              textAlign: 'center',
            }}
              data-test-id="sendclick"
              onClick={this.callSendNotification}
              >
              <Typography style={{
                color: '#2B2B2B',
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: 'Jost',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '28px',
                cursor: 'pointer'
              }}>
                Send
              </Typography>
            </Box>
      
          </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
          </Grid>
          </Grid>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    // width: "100%",
    padding: "20px"
  },
  required: {
    color: '#838383',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
  },
  fullname: {
    color: '#292929',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Jost',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  avatarstyle: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: '#F6EEFF', // Set the background color
    color: 'black',
  },
  passworderror: {
    maxWidth: "300px",
    fontSize: "12px",
    color: "#F44336",
    fontFamily: "Inter",
    marginTop: "5px",

  },
  boxNew: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '10px'
  },
  box2: {
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Jost",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "50px"
  },
  box3: {
    display: 'flex',
    justifyContent: 'right',
    height: "8%",
    marginLeft: '10px',
  }, imag1: {
    width: '128px',
    height: '44px',
    flexShrink: 0,
    marginRight: "10px"
  },
  img2:
  {
    width: '200px',
    height: '44px',
    flexShrink: 0,
  },
  para: {
    color: '#414141',
    fontFamily: 'Jost',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
  },
  tab1: {
    color: '#A046FB',
    fontFamily: 'Jost',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '26px',
  }, tab2: {
    color: '#A046FB',
    fontFamily: 'Jost',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '26px',
  },
  drawerPaper: {
    backgroundColor: 'transparent',
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
};
// Customizable Area End
